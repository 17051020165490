<template lang="html">

  <section class="results">
    <div>
      <hero-global img="/img/clarity/clarity-1.svg" title="Búsqueda" subtitle="Búsqueda en CERMI" />
      <section class="container container-results">
        <div class="gcse-searchbox-only"></div>
        <div class="gcse-searchresults-only"></div>
      </section>

    </div>
  </section>

</template>

<script lang="js">
  import HeroGlobal from "@/components/Global/HeroGlobal";

  export default {
    name: 'results',
    components: {
      HeroGlobal
    },
    props: [],
    mounted() {

    },
    data() {
      return {

      }
    },
    methods: {

    },
    computed: {

    }
  }


</script>

<style scoped lang="scss">

</style>